const cardWidthKey = process.env.VUE_APP_UTILS_CARD_WIDTH;
// const cardHeightKey = process.env.VUE_APP_UTILS_CARD_HEIGHT;
const cardHeadKey = process.env.VUE_APP_UTILS_CARD_HEAD;
const cardTailKey = process.env.VUE_APP_UTILS_CARD_TAIL;

const chartTypeKey = process.env.VUE_APP_UTILS_CHART_TYPE;
const chartRangeKey = process.env.VUE_APP_UTILS_CHART_RANGE;

export default {
  cardHead(args) {
    let result = undefined;
    if (args == undefined) {
      result = localStorage.getItem(cardHeadKey);
      if (result == undefined) {
        result = true;
        localStorage.setItem(cardHeadKey, result);
      }
    } else {
      result = args;
      localStorage.setItem(cardHeadKey, args);
    }
    return result;
  },

  cardTail(args) {
    let result = undefined;
    if (args == undefined) {
      result = localStorage.getItem(cardTailKey);
      if (result == undefined) {
        result = "true";
        localStorage.setItem(cardTailKey, result);
      }
    } else {
      result = args;
      localStorage.setItem(cardTailKey, args);
    }
    return result;
  },

  cardWidth(args) {
    let result = undefined;
    if (args == undefined) {
      result = localStorage.getItem(cardWidthKey);
      if (result == undefined) {
        result = 4;
        localStorage.setItem(cardWidthKey, result);
      }
    } else {
      result = args;
      localStorage.setItem(cardWidthKey, args);
    }
    return result;
  },

  cardHeight(args) {
    let result = undefined;
    if (args == undefined) {
      // result = localStorage.getItem(cardHeightKey);
      if (result == undefined) {
        result = 400;
        // localStorage.setItem(cardHeightKey, result);
      }
    } else {
      result = args;
      // localStorage.setItem(cardHeightKey, args);
    }
    return result;
  },

  chartType(args) {
    let result = undefined;
    if (args == undefined) {
      result = localStorage.getItem(chartTypeKey);
      if (result == undefined) {
        result = false; //충전량...
        localStorage.setItem(chartTypeKey, result);
      }
    } else {
      result = args;
      localStorage.setItem(chartTypeKey, args);
    }
    return result;
  },

  // 통계 날짜 범위가 today or week or month
  // args: today=0, week=1, month=2
  chartRange(args) {
    let result = undefined;
    if (args == undefined) {
      result = localStorage.getItem(chartRangeKey);
      if (result == undefined) {
        result = 2; //month..
        localStorage.setItem(chartRangeKey, result);
      }
    } else {
      result = args;
      localStorage.setItem(chartRangeKey, args);
    }
    return result;
  },

  chartStart(today) {
    let range = this.chartRange();
    if (range == 0) {
      return today.format("YYYY-MM-DD");
    } else if (range == 1) {
      return today.clone().startOf("week").format("YYYY-MM-DD");
    } else if (range == 2) {
      return today.clone().startOf("month").format("YYYY-MM-DD");
    } else if (range == 3) {
      return today.clone().startOf("year").format("YYYY-MM-DD");
    }
    return today.format("YYYY-MM-DD");
  },

  chartEnd(today) {
    let range = this.chartRange();
    if (range == 0) {
      return today.format("YYYY-MM-DD");
    } else if (range == 1) {
      return today.clone().endOf("week").format("YYYY-MM-DD");
    } else if (range == 2) {
      return today.clone().endOf("month").format("YYYY-MM-DD");
    } else if (range == 3) {
      return today.clone().endOf("year").format("YYYY-MM-DD");
    }
    return today.format("YYYY-MM-DD");
  },

  chartCollection(label, labels, data) {
    return {
      labels: labels,
      datasets: [
        {
          label: label,
          backgroundColor: "#f87979",
          data: data,
        },
      ],
    };
  },

  chartOption(unit) {
    return {
      title: {
        display: true,
        text: unit,
      },
      tooltips: {
        callbacks: {
          label: (item) => `${item.yLabel.toFixed(2)} ${unit}`,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      // height: 100,
    };
  },

  /**
   * @param UTC
   */
  dateFormat(UTC) {
    let date = new Date(UTC);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;
    hour = hour >= 10 ? hour : "0" + hour;
    minute = minute >= 10 ? minute : "0" + minute;
    second = second >= 10 ? second : "0" + second;

    return (
      date.getFullYear() +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      second
    );
  },
};
