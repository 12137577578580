<template>
  <v-card>
    <v-card-title> 충전 내역 </v-card-title>
    <v-card-subtitle> Rocord of charging a vehicle </v-card-subtitle>

    <v-card-text>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        :items="elements"
        :loading="elementSearch.loading"
        :headers="elementSearch.headers"
        :server-items-length="elementSearch.totalElements"
        :options="elementSearchForm"
        @update:options="(val) => (elementSearchForm = val)"
        multi-sort
        class="elevation-24"
      >
        <template v-slot:top>
          <v-row no-gutters class="mb-10">
            <!-- <v-select
              v-model="elementSearchForm.query.searchTarget"
              :items="searchTargets"
              label="충전소 및 충전기"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
              full-width
            ></v-select> -->
            <v-text-field
              v-model="elementSearchForm.query.stationName"
              label="충전소"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="elementSearchForm.query.chargerId"
              label="충전기"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-select
              v-model="elementSearchForm.query.isSoftberry"
              :items="isSoftberry"
              label="환경부 충전"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
              full-width
            ></v-select>
            <v-select
              v-model="elementSearchForm.query.result"
              :items="result"
              label="환경부 등록"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
              full-width
            ></v-select>
            <v-text-field
              class="ml-5"
              v-model="elementSearchForm.query.username"
              label="카드번호"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-text-field
              class="ml-5"
              v-model="elementSearchForm.query.serverTransaction"
              label="serverTx"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-text-field
              class="ml-5"
              v-model="elementSearchForm.query.clientTransaction"
              label="clientTx"
              outlined
              dense
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="resetElements(false)">
              Search
            </v-btn>
            <v-btn text color="primary" @click="resetElements(true)">
              Reset
            </v-btn>
          </v-row>
        </template>

        <template v-slot:[`item.seq`]="{ item }">
          <a @click="loadElement(item)">
            {{ item.seq }}
          </a>
        </template>
        <template v-slot:[`item.timestampStart`]="{ item }">
          {{
            item.timestampStart ? $moment(item.timestampStart).format() : "-"
          }}
        </template>
        <template v-slot:[`item.timestampEnd`]="{ item }">
          {{ item.timestampEnd ? $moment(item.timestampEnd).format() : "-" }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ item.price != undefined ? item.price.toFixed(2) : "-" }}
        </template>
        <template v-slot:[`item.result`]="{ item }">
          <div
            :style="{
              color:
                item.result === '성공'
                  ? '#18CC8B'
                  : item.result === '실패'
                  ? '#F63E3E'
                  : item.result === '미전송'
                  ? '#FFBA53'
                  : '#ACAFB2',
            }"
          >
            {{ item.result }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="isOpenElement" width="80%" @input="handleClose">
      <!-- 환경부 재전송시 로딩바 구현(화면 전체 overlay) -->
      <v-overlay :value="loading"
        ><v-progress-circular
          v-if="loading"
          :size="64"
          color="#61E8A7"
          indeterminate
        ></v-progress-circular
      ></v-overlay>

      <v-card>
        <v-app-bar color="deep-purple accent-4" dense>
          <v-toolbar-title>충전 내역</v-toolbar-title>
        </v-app-bar>

        <v-card-subtitle>&nbsp;</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="element.serverTransaction"
                outlined
                label="Server Transaction"
                filled
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="element.clientTransaction"
                outlined
                label="Client Transaction"
                filled
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                outlined
                label="충전량(kwh)"
                filled
                readonly
                :value="
                  element.kw ? Number.parseFloat(element.kw).toFixed(2) : '0'
                "
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                outlined
                label="충전요금(원)"
                filled
                readonly
                :value="
                  element.price
                    ? Number.parseFloat(element.price).toFixed(2)
                    : '0'
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <div class="d-flex space-between px-5 mb-3">
          <v-btn
            :style="{
              color: isHistoryTab ? '#000000' : '#E0E0E0',
              backgroundColor: isHistoryTab ? '#ffffff' : '#656565',
            }"
            style="
              padding: 4px 10px;
              width: 72px;
              height: 24px;
              border-radius: 6px;
              margin-right: 8px;
            "
            @click="isHistoryTab = true"
            >통신 이력</v-btn
          >
          <v-btn
            :style="{
              color: isHistoryTab ? '#E0E0E0' : '#000000',
              backgroundColor: isHistoryTab ? '#656565' : '#ffffff',
            }"
            style="
              padding: 4px 10px;
              width: 112px;
              height: 24px;
              border-radius: 6px;
            "
            @click="isHistoryTab = false"
            >환경부 전송 이력</v-btn
          >

          <v-btn
            @click="retryRoaming(element.result)"
            v-if="!isHistoryTab && isDisplay"
            class="ml-auto"
            :disabled="isDisabled ? true : false"
            style="
              padding: 3px 24px;
              width: 84px;
              height: 22px;
              border-radius: 6px;
              color: #2f3235;
              background-color: #18cc8b;
            "
            >재전송</v-btn
          >
        </div>
        <v-card-text>
          <!-- 통신 이력 -->
          <v-simple-table dense v-if="isHistoryTab">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="border: 1px gray solid" class="text-center">
                    OCPP Message
                  </th>
                  <th style="border: 1px gray solid" class="text-left">
                    통신 시각
                  </th>
                  <th style="border: 1px gray solid" class="text-left">
                    통신 유형
                  </th>
                  <th style="border: 1px gray solid" class="text-left">
                    사용량(kwh)
                  </th>
                  <th style="border: 1px gray solid" class="text-center">
                    사용금액(원)
                  </th>
                  <th style="border: 1px gray solid" class="text-center">
                    과금 증가분
                  </th>
                  <th style="border: 1px gray solid" class="text-center">
                    과금 정책
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(message, index) in messages" :key="index">
                  <td style="border: 1px gray solid">
                    <v-expansion-panels class="ma-0 pa-0">
                      <v-expansion-panel class="ma-0 pa-0">
                        <v-expansion-panel-header class="ma-0 pa-0">
                          #{{ index + 1 }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="ma-0 pa-0">
                          <pre>{{ message.ocpp }}</pre>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </td>
                  <td style="border: 1px gray solid" class="text-left">
                    {{ message.timestamp }}
                  </td>
                  <td style="border: 1px gray solid" class="text-left">
                    {{ message.transactionType }}
                  </td>
                  <td style="border: 1px gray solid" class="text-right">
                    {{ message.transactionKw }}
                  </td>
                  <td style="border: 1px gray solid" class="text-right">
                    {{
                      message.totalCost
                        ? Number.parseFloat(message.totalCost).toFixed(2)
                        : message.totalCost
                    }}
                  </td>
                  <td style="border: 1px gray solid" class="text-right">
                    {{
                      message.incrementPrice
                        ? Number.parseFloat(message.incrementPrice).toFixed(2)
                        : message.incrementPrice
                    }}
                    ={{
                      message.incrementKw
                        ? Number.parseFloat(message.incrementKw).toFixed(2)
                        : message.incrementKw
                    }}
                    *{{
                      message.policyPrice
                        ? Number.parseFloat(message.policyPrice).toFixed(2)
                        : message.policyPrice
                    }}
                  </td>
                  <td style="border: 1px gray solid" class="text-right">
                    {{ message.policyName }} ({{ message.policyId }}/{{
                      message.policyRevision
                    }})
                  </td>
                  <!-- 
                    <td style="border: 1px gray solid" class="text-center">
                      <v-btn color="error" v-if="v.edit" text x-small @click="equipmentsMetadataOpen('edit', v)">Edit</v-btn>
                      <v-btn color="error" v-if="! v.edit" text x-small @click="equipmentsMetadataOpen('remove', v)">Remove</v-btn> 
                    </td>
                    -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- 환경부 전송 이력 -->
          <v-simple-table v-if="!isHistoryTab">
            <thead>
              <tr>
                <th style="border: 1px gray solid" class="text-center">
                  전송이력
                </th>
                <th style="border: 1px gray solid" class="text-center">
                  전송 시각
                </th>
                <th style="border: 1px gray solid" class="text-center">
                  성공/실패
                </th>
                <th style="border: 1px gray solid" class="text-center">
                  에러 메세지
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(history, index) in roamingHistoryList" :key="index">
                <td style="border: 1px gray solid" class="text-center">
                  #{{ history.rownum }}
                </td>
                <td style="border: 1px gray solid" class="text-center">
                  {{ history.timeStamp }}
                </td>
                <td
                  style="border: 1px gray solid"
                  class="text-center"
                  :style="{
                    color:
                      history.result === '성공'
                        ? '#18CC8B'
                        : history.result === '실패'
                        ? '#F63E3E'
                        : history.result === '미전송'
                        ? '#FFBA53'
                        : '#ACAFB2',
                  }"
                >
                  {{ history.result }}
                </td>
                <td style="border: 1px gray solid" class="text-center">
                  {{ history.errorMessage }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import OCPP_MESSAGE from "@/app/env.ocpp.message.js";
import Utils from "@/app/app.utils.js";

export default {
  name: "Events",

  data: () => ({
    searchTargets: [], // 충전소 및 충전기 select list
    isSoftberry: [
      { value: null, text: "전체" },
      { value: false, text: "환경부" },
      { value: true, text: "EVI" },
    ], // 환경부 충전 select list
    result: [
      { value: null, text: "전체" },
      { value: "성공", text: "성공" },
      { value: "실패", text: "실패" },
      { value: "미전송", text: "미전송" },
    ], // 환경부 등록 select list

    element: {}, // 충전내역 한 건
    elements: [], // 충전내역 리스트

    elementSearch: {
      headers: [
        { text: "seq", value: "seq" },
        { text: "충전소", value: "stationName" },
        { text: "충전기", value: "chargerId" },
        { text: "커넥터", value: "connectorId" },
        { text: "카드번호", value: "username" },
        { text: "시작시각", value: "timestampStart", sortable: false },
        { text: "종료시각", value: "timestampEnd", sortable: false },
        { text: "kw", value: "kw", sortable: false },
        { text: "price", value: "price", sortable: false },
        { text: "상태", value: "state" },
        { text: "환경부 등록", value: "result" },
        { text: "serverTx", value: "serverTransaction" },
        { text: "clientTx", value: "clientTransaction" },
      ],
      totalElements: 0,
      loading: false,
    },
    elementSearchForm: {
      query: { owner: true },
      sortBy: ["seq"],
      sortDesc: ["desc"],
    }, // 충전내역 리스트 조회 조건

    isOpenElement: false, // 충전내역 다이얼로그 open

    messages: [], // 통신 이력 리스트
    roamingHistoryList: [], // 환경부 전송 이력 리스트

    isHistoryTab: true, // 통신 이력 / 환경부 전송 이력 탭

    loading: false, // 재전송시 로딩바
    isDisplay: false, // 재전송 버튼 표시 여부
    isDisabled: false, // 재전송 버튼 disabled
  }),

  methods: {
    /** close */
    close() {
      this.isOpenElement = false;
      this.roamingHistoryList = []; // 환경부 전송 이력 리스트 리셋
      this.isDisplay = false;
      this.isHistoryTab = true;
    },

    /** close 버튼 없이 바깥 영역 클릭으로 모달이 닫혔을 경우 */
    handleClose() {
      this.roamingHistoryList = []; // 환경부 전송 이력 리스트 리셋
      this.isDisplay = false;
      this.isHistoryTab = true;
    },

    /**
     * SEARCH RESET 버튼
     * @param reset
     */
    resetElements(reset) {
      if (reset) {
        this.elementSearchForm.query = { owner: true };
        this.elementSearchForm.page = 1;
        this.elementSearchForm.itemsPerPage = 10;
        this.elementSearchForm.sortBy = ["seq"];
        this.elementSearchForm.sortDesc = ["desc"];
        // this.loadSearchTargets();
      } else {
        this.elementSearchForm.page = 1; // SEARCH 버튼으로 검색시 페이지 1로
        this.searchElements();
      }
    },

    /** 충전내역 리스트 조회 */
    searchElements() {
      OCPP_API.events.searchEvents(this.elementSearchForm).then((r) => {
        this.elements = r.data.eventResDtoList;
        this.elementSearch.totalElements = r.data.totalElements;
        this.elementSearch.loading = false;
      });
    },

    /**
     * 재전송 버튼
     * @param result
     */
    retryRoaming(result) {
      // result === "" : EVI or 환경부인데 ENDED가 아닌 경우
      if (result === "성공" || result === "") return;

      this.isDisabled = true; // 재전송 버튼 disabled
      this.loading = true; // 로딩바
      if (result === "미전송" || result === "실패") {
        OCPP_API.events
          .retryEvent({
            query: {
              seq: Number(this.element.seq),
            },
          })
          .then(() => {
            // 환경부 전송 이력 조회 API
            OCPP_API.events
              .searchEvent({
                seq: Number(this.element.seq),
              })
              .then((r) => {
                this.isDisabled = false; // 재전송 버튼 abled
                this.loading = false; // 로딩바 멈춤

                // 재전송을 해야 되는지 여부
                if (!r.data.isSucceed) {
                  this.isDisplay = true; // 재전송 버튼 표시
                } else {
                  this.isDisplay = false;
                }

                this.roamingHistoryList = []; // 환경부 전송 이력
                r.data.apiHistoryList.forEach((h) => {
                  h.timeStamp = Utils.dateFormat(h.timeStamp);
                  this.roamingHistoryList.push(h);
                });
              });
          });
      }
    },

    /**
     * 충전내역 상세 조회 = 통신이력 + 환경부 전송이력
     * @param element
     */
    loadElement(element) {
      this.isOpenElement = true;
      this.element = element;

      // 환경부 전송 이력 조회 API
      OCPP_API.events
        .searchEvent({
          seq: Number(element.seq),
        })
        .then((r) => {
          // 재전송을 해야 되는지 여부
          if (!r.data.isSucceed) {
            this.isDisplay = true; // 재전송 버튼 표시
          } else {
            this.isDisplay = false;
          }

          this.roamingHistoryList = [];
          r.data.apiHistoryList.forEach((h) => {
            h.timeStamp = Utils.dateFormat(h.timeStamp);
            this.roamingHistoryList.push(h);
          });
        });

      const source = `/${element.version}/${element.stationId}/${element.chargerId}`;

      // 통신 이력 조회 API
      const searchMessage = {
        page: 1,
        itemsPerPage: 10000000,
        sortBy: "timestamp",
        query: {
          source: source,
          message: element.clientTransaction,
        },
      };

      this.messages = [];
      OCPP_MESSAGE.messages.search(searchMessage).then((r) => {
        console.log(
          this.$options.name,
          "searchMessage",
          r.data._embedded.messages
        );

        let message = null;
        r.data._embedded.messages.forEach((m) => {
          let ocpp = null;
          try {
            ocpp = JSON.parse(m.message);
          } catch {
            ocpp = m.message;
          }

          if (m.source == source && 2 == ocpp[0]) {
            // console.log(this.$options.name, "req", k, ocpp);

            let kw = "-";
            ocpp[3].meterValue.forEach((meterValue) => {
              meterValue.sampledValue.forEach((sampledValue) => {
                if (
                  "Energy.Active.Import.Interval" === sampledValue.measurand
                ) {
                  kw = sampledValue.value;
                }
              });
            });

            message = {
              transactionId: ocpp[3].transactionInfo.transactionId,
              transactionType: ocpp[3].eventType,
              transactionKw: kw,
              timestamp: ocpp[3].timestamp,
              ocpp: ocpp[3],
            };

            this.messages.push(message);
          } else if (m.source == source && 3 == ocpp[0]) {
            if (message != null) {
              this.$set(
                message,
                "policyId",
                ocpp[2].customData.additionalProperties.policyId
              );
              this.$set(
                message,
                "policyName",
                ocpp[2].customData.additionalProperties.policyName
              );
              this.$set(
                message,
                "policyRevision",
                ocpp[2].customData.additionalProperties.policyRevision
              );
              this.$set(
                message,
                "policyPrice",
                ocpp[2].customData.additionalProperties.policyPrice
              );
              this.$set(
                message,
                "incrementKw",
                ocpp[2].customData.additionalProperties.incrementKw
              );
              this.$set(
                message,
                "incrementPrice",
                ocpp[2].customData.additionalProperties.incrementPrice
              );
              this.$set(
                message,
                "totalCost",
                ocpp[2].customData.additionalProperties.totalCost
              );

              message = null;
            }
          }
        });
      });
    },

    /** 충전소 및 충전기 select load */
    // loadSearchTargets() {
    //   const params = {
    //     query: { owner: true },
    //     sortBy: ["stationName"],
    //     sortDesc: ["desc"],
    //     page: 1,
    //     itemsPerPage: 1000,
    //   };

    //   OCPP_API.managements.search(params).then((r1) => {
    //     const managements = r1.data._embedded.managements;

    //     OCPP_API.equipments.search(params).then((r2) => {
    //       const equipments = r2.data._embedded.equipments;

    //       this.searchTargets = [];

    //       managements.forEach((m) => {
    //         this.searchTargets.push({
    //           value: m.id,
    //           text: "충전소[" + m.name + "] 전체 ",
    //         });
    //         equipments.forEach((e) => {
    //           if (m.id == e.management.id) {
    //             this.searchTargets.push({
    //               value: e.id,
    //               text: "충전소[" + m.name + "] / 충전기[" + e.name + "]",
    //             });
    //           }
    //         });
    //       });
    //     });
    //   });
    // },
  },

  mounted() {
    // this.loadSearchTargets();
  },

  watch: {
    elementSearchForm: {
      handler: "searchElements",
      // deep: true, //즉시 변경시...
    },
  },
};
</script>

<style></style>
