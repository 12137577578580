import Authorization from "@/app/env.authorization.js";
import axios from "axios";
import qs from "qs";

const uri = process.env.VUE_APP_OCPP_API_SERVER; //8085

const OcppMessage = {
  headers(h) {
    return Authorization.getAuthorizationHeader(h);
  },
  params(vue_data_table_options) {
    let params = {};

    if (vue_data_table_options["page"] != undefined) {
      params.page = vue_data_table_options["page"] - 1;
    }
    if (vue_data_table_options["itemsPerPage"] != undefined) {
      params.size = vue_data_table_options["itemsPerPage"];
    }

    let sort = [];
    if (
      Array.isArray(vue_data_table_options["sortBy"]) &&
      Array.isArray(vue_data_table_options["sortDesc"])
    ) {
      for (var i = 0; i < vue_data_table_options["sortBy"].length; i++) {
        const property = vue_data_table_options["sortBy"][i];
        const direction = vue_data_table_options["sortDesc"][i]
          ? "desc"
          : "asc";
        sort.push(property + "," + direction);
      }
    }
    params.sort = sort;
    // console.log(params);
    return params;
  },

  data(vue_data_table_options) {
    if (vue_data_table_options["query"] != undefined) {
      return vue_data_table_options["query"];
    }
    return vue_data_table_options;
  },

  messages: {
    search(vue_data_table_options, h) {
      const params = Object.assign(
        OcppMessage.params(vue_data_table_options),
        OcppMessage.data(vue_data_table_options)
      );
      console.log(params);

      return axios({
        headers: OcppMessage.headers(h),
        url: `${uri}/messages/search/findBySourceAndMessageRegex`,
        method: "GET",
        params: params,
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: "repeat" });
        },
      });
    },
  },
};

export default OcppMessage;
